import moment from "moment-timezone";

export type KeyOfType<T, V> = { [K in keyof T]: T[K] extends V ? K : never }[keyof T]; // 'keyof' operator that filters properties by their value type V

export function hasValue(value: string | null | undefined): value is string {
    return !!value && value.trim().length > 0;
}

export function isNullOrEmpty(value: string | null | undefined): value is null | undefined {
    return !value || value.trim().length === 0;
}

export function getNonEmpty(value: string | number | null | undefined, defaultValue = "-"): string {
    if (typeof value === "number") {
        return value.toString();
    }

    return hasValue(value) ? value : defaultValue;
}

export function now(): Date {
    return moment().toDate();
}

export function truncateText(text: string, maxLength: number): string {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.slice(0, maxLength) + "...";
    }
}

export function getParentElementWithByClass(element: HTMLElement, className: string): HTMLElement | false {
    let currentElement = element;

    while (currentElement && currentElement !== document.documentElement) {
        if (currentElement.classList.contains(className)) {
            return currentElement;
        }
        // @ts-expect-error
        currentElement = currentElement.parentElement;
    }

    return false;
}

export function distinct<T>(array: T[]): T[] {
    return array.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
            index ===
            array.findIndex((obj) => {
                return JSON.stringify(obj) === _value;
            })
        );
    });
}

export function getRandomComponentId(prefix?: string): string {
    const innerPrefix = isNullOrEmpty(prefix) ? "" : `${prefix}-`;
    return `${innerPrefix}${Math.random().toString(36).substring(2)}`;
}

function cleanseAssertionOperators(parsedName: string): string {
    return parsedName.replace(/[?!]/g, "");
}

export function nameof<T>(nameFunction: (obj: T) => any): string {
    const functionString = nameFunction.toString();
    // ES6 prop selector:
    // "x => x.prop"
    if (functionString.includes("=>")) {
        return cleanseAssertionOperators(functionString.substring(functionString.lastIndexOf(".") + 1)); // TODO take last prop only
    }

    throw new Error(`Unsupported name function ${functionString}`);
}
